<template>
    <el-row class="warp">
        <el-col :span="24" class="warp-breadcrum">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{path:'/'}"><b>首页</b></el-breadcrumb-item>
                <el-breadcrumb-item>内容管理</el-breadcrumb-item>
                <el-breadcrumb-item>一日游</el-breadcrumb-item>
                <el-breadcrumb-item>旅行社</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
        <el-col :span="24" class="warp-main">
            <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
                <el-form :inline="true" :model="filters">
                    <el-form-item>
                        <el-input v-model="filters.name" placeholder="请输入名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-on:click="getTourGroupListPage" icon="search">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-on:click="goReset" icon="search">重置</el-button>
                    </el-form-item>
                    <el-form-item >
                        <el-button type="primary" icon="plus" v-if="permissionButton.add" @click="$router.push({path:'/tourGroup/add'})">新增</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-table :data="tourGroup" highlight-current-row v-loading="listLoading" @selection-change="selsChange" style="width: 100%;" border>
                <el-table-column type="selection" width="50" align="center"></el-table-column>
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="name" label="名称" min-width="200"></el-table-column>
                <el-table-column prop="address" label="地址" min-width="400"></el-table-column>
                <el-table-column prop="indexImageUrl" label="列表缩略图片" width="130" align="center">
                    <template slot-scope="scope" >
                        <img style="width: 89px; height: 50px" :src="downloadFileURL+scope.row.indexImageUrl+'/'"/>
                    </template>
                </el-table-column>
                <el-table-column prop="contactNumber" label="联系电话" width="120" align="center"></el-table-column>
                <el-table-column prop="readNumber" label="阅读次数" width="120" align="center" sortable></el-table-column>
                <el-table-column prop="likeCount" label="点赞人数" width="120" align="center" sortable></el-table-column>
                <el-table-column prop="isTop" label="置顶" width="80" align="center" sortable>
                    <template slot-scope="scope" >
                        <span v-bind:class="{temporary: (scope.row.isTop)==0}">{{scope.row.isTop==0?'是':scope.row.isTop==1?'否':''}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="rate" label="星级评分" width="120" align="center" sortable></el-table-column>
                <el-table-column prop="updateTime" label="修改时间" width="180" align="center" sortable>
                    <template slot-scope="scope">
                        <span>{{scope.row.updateTime ? scope.row.updateTime : "暂无更改"}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="formatCreateTime" label="创建时间" width="180" align="center" sortable></el-table-column>
                <el-table-column label="操作" width="220" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="small" v-if="permissionButton.view" @click="$router.push({path:'/tourGroup/view',query:{id:scope.row.id}})">查看</el-button>
                        <el-button type="primary" size="small" v-if="permissionButton.edit" @click="$router.push({path:'/tourGroup/edit',query:{id:scope.row.id}})">编辑</el-button>
                        <el-button type="danger" @click="delBanner(scope.$index,scope.row)" size="small" v-if="permissionButton.del">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-col :span="24" class="table-footer">
                <el-button type="danger" size="small" @click="batchDeleteBanner" :disabled="this.sels.length===0" v-if="permissionButton.batchDel">批量删除</el-button>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10,15,20,25,50]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total" style="float:right;">
                </el-pagination>
            </el-col>
        </el-col>
    </el-row>
</template>
<script>
    import {downloadFileURL} from '../../../api/system-api'
    import {reqGetTourGroupListPage,reqDeleteTourGroup,reqBatchDeleteTourGroup}  from '../../../api/tour-api'
    import permission from '../../../common/permission';
    export default{
        data(){
            return{
                downloadFileURL:downloadFileURL,
                filters:{
                    name:'',
                },
                tourGroup:[],
                total:0,
                pageNum:1,
                pageSize:15,
                listLoading:false,
                sels:[],
                permissionButton:new permission.PermissionButton,
            }
        },methods:{
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getTourGroupListPage();
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.pageSize=val;
                this.getTourGroupListPage();
            },

            getTourGroupListPage() {
                let para = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    name: this.filters.name,
                };
                this.listLoading = true;
                reqGetTourGroupListPage(para).then((res) => {
                    this.total = res.data.data.total;
                    this.tourGroup = res.data.data.records;
                    this.listLoading = false;
                })
            },
            selsChange: function (sels) {
                this.sels = sels;
            },


            goReset(){
                this.filters.name='';
                this.pageNum=1;
                this.getTourGroupListPage();
            },

            // 删除
            delBanner: function(index, row) {
                this.$confirm('确认删除该记录吗?', '提示', {type: 'warning'}).then(() => {
                    this.listLoading = true;
                    let para = {id: row.id};
                    reqDeleteTourGroup(para).then((res) => {
                        if(res.data.code===1){
                            this.listLoading = false;
                            this.$message({
                                message: '删除成功',
                                type: 'success'
                            });
                            this.getTourGroupListPage();
                        }else {
                            this.listLoading = false;
                            this.$message({
                                message: '删除失败',
                                type: 'error'
                            });
                            this.getTourGroupListPage();
                        }
                    });
                }).catch(() => {
                });
            },
            // 批量删除
            batchDeleteBanner: function () {
                var ids = this.sels.map(item => item.id).toString();
                this.$confirm('确认删除选中记录吗？', '提示', {
                    type: 'warning'
                }).then(() => {
                    this.listLoading = true;
                    let para = {ids: ids};
                    reqBatchDeleteTourGroup(para).then((res) => {
                        if(res.data.code===1){
                            this.listLoading = false;
                            this.$message({
                                message: '删除成功',
                                type: 'success'
                            });
                            this.getTourGroupListPage();
                        }else {
                            this.listLoading = false;
                            this.$message({
                                message: '删除失败',
                                type: 'error'
                            });
                            this.getTourGroupListPage();
                        }
                    });
                }).catch(() => {

                });
            },

        },mounted(){
            this.getTourGroupListPage();
            var permissionId = sessionStorage.getItem("permissionId");
            permission.getPermissionButton(permissionId,this.permissionButton);
        }
    }
</script>
